import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ArticleCard = makeShortcode("ArticleCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<ArticleCard>`}</inlineCode>{` component should generally be used inside of a `}<inlineCode parentName="p">{`<Row>`}</inlineCode>{` and
`}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` with a `}<inlineCode parentName="p">{`noGutterMdLeft`}</inlineCode>{` prop on the `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` to allow it to “hang”
to the left.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard subTitle="subTitle" title="Title" author="Josh Black" date="April 29, 2019" readTime="Read time: 5 min" href="/" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz3XTaUzTBxjH8ace8zZT4xJjpkwTFRVpnYTVVv/YgtNsYVOJ4JEIxkTEIx5TNAEPEAUaYPNKjM4zVEExKho80C2KCZRWLrUgUDvxABUVb2j7/5pqYnyzF0/ye/V59X1ks0Mlwwkxh18gSgWacAcSVsJCwwMsk96THqaSraikKO0EmBsQUxmdlOtolOt0jrAjegcR2c1Y6mCTXUW+gEdeIqHFiDYfGVfAXG0VyWNqSda52KK843uzE9EXIkF5SNApJPgUostHgs//D3ioFQk+R695NYzOecnvaV7iV6gk6N18F1qOGEqQkCIGrHSjO/yWoJ1t9FtcjwSeISKz6SvQrpJRC9G5r+i1yE3UP5DeCFlLYEU86P56TfcF9fSNbWRIWiuxJ9+TnNjCnw0QVw59lzURvvMZlrt+0IekVvqHl+AdLRgKOth/DwovPyR+Qj7W3DcsroaArS4Gb7hDdClYr35gfWgJB/ZVc9wN04t8jMxpYd2NdtKqQNKdYEqyIYFWfj7djssDaxduZVAXA+56SHdBn6hLaIx5JJRBWwcYtTOZPX4Vz97B3H9BJhYSFHuRbbdB0mpgxLzzyKhjaA+8IasU9sS0smpkKbt3NbPABt2mFSEheURegb3F7STpbRQWP2f/f2Aq8CEhZ+kflsuWChC/Omp+EfLDIQbEuYi2qCRGwXJjHbMW3SUgtRXRnUTGHmXgmgfEnOtg3d+vSaqBWVdVvk1oQgJP0N9kJaUSZHsNzDnoYmDERZbq29g4w0fcH160iU/ovaQRMVUiEwqQ8VZEcdAztp7hlqcEbHpMt2jnp3a/US7wS0YV226BpN6ClDIPG2beJ8vgI8voIUfvZehkJ2IsRWOuRH48gWiPIuabyKRyZKINMZSjmeJA9DbMlkfkNII/Qdl8zcPSaXZWj6gmc4pKhuIhM8zLMFMdEm6n668N9PitgR6RdXSNvIeE+7/p5qfrNLUC+cnG1OxmMms/d/gRW4lAVhe+peQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard title="Explore & Create" author="Josh Black" href="https://www.ibm.com" actionIcon="arrowRight" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz3XTaUzTBxjH8ace8zZT4xJjpkwTFRVpnYTVVv/YgtNsYVOJ4JEIxkTEIx5TNAEPEAUaYPNKjM4zVEExKho80C2KCZRWLrUgUDvxABUVb2j7/5pqYnyzF0/ye/V59X1ks0Mlwwkxh18gSgWacAcSVsJCwwMsk96THqaSraikKO0EmBsQUxmdlOtolOt0jrAjegcR2c1Y6mCTXUW+gEdeIqHFiDYfGVfAXG0VyWNqSda52KK843uzE9EXIkF5SNApJPgUostHgs//D3ioFQk+R695NYzOecnvaV7iV6gk6N18F1qOGEqQkCIGrHSjO/yWoJ1t9FtcjwSeISKz6SvQrpJRC9G5r+i1yE3UP5DeCFlLYEU86P56TfcF9fSNbWRIWiuxJ9+TnNjCnw0QVw59lzURvvMZlrt+0IekVvqHl+AdLRgKOth/DwovPyR+Qj7W3DcsroaArS4Gb7hDdClYr35gfWgJB/ZVc9wN04t8jMxpYd2NdtKqQNKdYEqyIYFWfj7djssDaxduZVAXA+56SHdBn6hLaIx5JJRBWwcYtTOZPX4Vz97B3H9BJhYSFHuRbbdB0mpgxLzzyKhjaA+8IasU9sS0smpkKbt3NbPABt2mFSEheURegb3F7STpbRQWP2f/f2Aq8CEhZ+kflsuWChC/Omp+EfLDIQbEuYi2qCRGwXJjHbMW3SUgtRXRnUTGHmXgmgfEnOtg3d+vSaqBWVdVvk1oQgJP0N9kJaUSZHsNzDnoYmDERZbq29g4w0fcH160iU/ovaQRMVUiEwqQ8VZEcdAztp7hlqcEbHpMt2jnp3a/US7wS0YV226BpN6ClDIPG2beJ8vgI8voIUfvZehkJ2IsRWOuRH48gWiPIuabyKRyZKINMZSjmeJA9DbMlkfkNII/Qdl8zcPSaXZWj6gmc4pKhuIhM8zLMFMdEm6n668N9PitgR6RdXSNvIeE+7/p5qfrNLUC+cnG1OxmMms/d/gRW4lAVhe+peQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard title="Explore & Create a longer title example in this space" href="https://www.ibm.com" disabled mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz3XTaUzTBxjH8ace8zZT4xJjpkwTFRVpnYTVVv/YgtNsYVOJ4JEIxkTEIx5TNAEPEAUaYPNKjM4zVEExKho80C2KCZRWLrUgUDvxABUVb2j7/5pqYnyzF0/ye/V59X1ks0Mlwwkxh18gSgWacAcSVsJCwwMsk96THqaSraikKO0EmBsQUxmdlOtolOt0jrAjegcR2c1Y6mCTXUW+gEdeIqHFiDYfGVfAXG0VyWNqSda52KK843uzE9EXIkF5SNApJPgUostHgs//D3ioFQk+R695NYzOecnvaV7iV6gk6N18F1qOGEqQkCIGrHSjO/yWoJ1t9FtcjwSeISKz6SvQrpJRC9G5r+i1yE3UP5DeCFlLYEU86P56TfcF9fSNbWRIWiuxJ9+TnNjCnw0QVw59lzURvvMZlrt+0IekVvqHl+AdLRgKOth/DwovPyR+Qj7W3DcsroaArS4Gb7hDdClYr35gfWgJB/ZVc9wN04t8jMxpYd2NdtKqQNKdYEqyIYFWfj7djssDaxduZVAXA+56SHdBn6hLaIx5JJRBWwcYtTOZPX4Vz97B3H9BJhYSFHuRbbdB0mpgxLzzyKhjaA+8IasU9sS0smpkKbt3NbPABt2mFSEheURegb3F7STpbRQWP2f/f2Aq8CEhZ+kflsuWChC/Omp+EfLDIQbEuYi2qCRGwXJjHbMW3SUgtRXRnUTGHmXgmgfEnOtg3d+vSaqBWVdVvk1oQgJP0N9kJaUSZHsNzDnoYmDERZbq29g4w0fcH160iU/ovaQRMVUiEwqQ8VZEcdAztp7hlqcEbHpMt2jnp3a/US7wS0YV226BpN6ClDIPG2beJ8vgI8voIUfvZehkJ2IsRWOuRH48gWiPIuabyKRyZKINMZSjmeJA9DbMlkfkNII/Qdl8zcPSaXZWj6gmc4pKhuIhM8zLMFMdEm6n668N9PitgR6RdXSNvIeE+7/p5qfrNLUC+cnG1OxmMms/d/gRW4lAVhe+peQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard title="Explore & Create" color="dark" href="https://www.ibm.com" actionIcon="download" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz3XTaUzTBxjH8ace8zZT4xJjpkwTFRVpnYTVVv/YgtNsYVOJ4JEIxkTEIx5TNAEPEAUaYPNKjM4zVEExKho80C2KCZRWLrUgUDvxABUVb2j7/5pqYnyzF0/ye/V59X1ks0Mlwwkxh18gSgWacAcSVsJCwwMsk96THqaSraikKO0EmBsQUxmdlOtolOt0jrAjegcR2c1Y6mCTXUW+gEdeIqHFiDYfGVfAXG0VyWNqSda52KK843uzE9EXIkF5SNApJPgUostHgs//D3ioFQk+R695NYzOecnvaV7iV6gk6N18F1qOGEqQkCIGrHSjO/yWoJ1t9FtcjwSeISKz6SvQrpJRC9G5r+i1yE3UP5DeCFlLYEU86P56TfcF9fSNbWRIWiuxJ9+TnNjCnw0QVw59lzURvvMZlrt+0IekVvqHl+AdLRgKOth/DwovPyR+Qj7W3DcsroaArS4Gb7hDdClYr35gfWgJB/ZVc9wN04t8jMxpYd2NdtKqQNKdYEqyIYFWfj7djssDaxduZVAXA+56SHdBn6hLaIx5JJRBWwcYtTOZPX4Vz97B3H9BJhYSFHuRbbdB0mpgxLzzyKhjaA+8IasU9sS0smpkKbt3NbPABt2mFSEheURegb3F7STpbRQWP2f/f2Aq8CEhZ+kflsuWChC/Omp+EfLDIQbEuYi2qCRGwXJjHbMW3SUgtRXRnUTGHmXgmgfEnOtg3d+vSaqBWVdVvk1oQgJP0N9kJaUSZHsNzDnoYmDERZbq29g4w0fcH160iU/ovaQRMVUiEwqQ8VZEcdAztp7hlqcEbHpMt2jnp3a/US7wS0YV226BpN6ClDIPG2beJ8vgI8voIUfvZehkJ2IsRWOuRH48gWiPIuabyKRyZKINMZSjmeJA9DbMlkfkNII/Qdl8zcPSaXZWj6gmc4pKhuIhM8zLMFMdEm6n668N9PitgR6RdXSNvIeE+7/p5qfrNLUC+cnG1OxmMms/d/gRW4lAVhe+peQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard subTitle="subTitle" title="Explore & Create" author="Josh Black" readTime="Read time: 5 min" color="dark" href="https://www.ibm.com" actionIcon="email" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz3XTaUzTBxjH8ace8zZT4xJjpkwTFRVpnYTVVv/YgtNsYVOJ4JEIxkTEIx5TNAEPEAUaYPNKjM4zVEExKho80C2KCZRWLrUgUDvxABUVb2j7/5pqYnyzF0/ye/V59X1ks0Mlwwkxh18gSgWacAcSVsJCwwMsk96THqaSraikKO0EmBsQUxmdlOtolOt0jrAjegcR2c1Y6mCTXUW+gEdeIqHFiDYfGVfAXG0VyWNqSda52KK843uzE9EXIkF5SNApJPgUostHgs//D3ioFQk+R695NYzOecnvaV7iV6gk6N18F1qOGEqQkCIGrHSjO/yWoJ1t9FtcjwSeISKz6SvQrpJRC9G5r+i1yE3UP5DeCFlLYEU86P56TfcF9fSNbWRIWiuxJ9+TnNjCnw0QVw59lzURvvMZlrt+0IekVvqHl+AdLRgKOth/DwovPyR+Qj7W3DcsroaArS4Gb7hDdClYr35gfWgJB/ZVc9wN04t8jMxpYd2NdtKqQNKdYEqyIYFWfj7djssDaxduZVAXA+56SHdBn6hLaIx5JJRBWwcYtTOZPX4Vz97B3H9BJhYSFHuRbbdB0mpgxLzzyKhjaA+8IasU9sS0smpkKbt3NbPABt2mFSEheURegb3F7STpbRQWP2f/f2Aq8CEhZ+kflsuWChC/Omp+EfLDIQbEuYi2qCRGwXJjHbMW3SUgtRXRnUTGHmXgmgfEnOtg3d+vSaqBWVdVvk1oQgJP0N9kJaUSZHsNzDnoYmDERZbq29g4w0fcH160iU/ovaQRMVUiEwqQ8VZEcdAztp7hlqcEbHpMt2jnp3a/US7wS0YV226BpN6ClDIPG2beJ8vgI8voIUfvZehkJ2IsRWOuRH48gWiPIuabyKRyZKINMZSjmeJA9DbMlkfkNII/Qdl8zcPSaXZWj6gmc4pKhuIhM8zLMFMdEm6n668N9PitgR6RdXSNvIeE+7/p5qfrNLUC+cnG1OxmMms/d/gRW4lAVhe+peQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard subTitle="subTitle" title="Explore & Create" author="Josh Black" date="April 29, 2019" readTime="Read time: 5 min" color="dark" disabled mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz3XTaUzTBxjH8ace8zZT4xJjpkwTFRVpnYTVVv/YgtNsYVOJ4JEIxkTEIx5TNAEPEAUaYPNKjM4zVEExKho80C2KCZRWLrUgUDvxABUVb2j7/5pqYnyzF0/ye/V59X1ks0Mlwwkxh18gSgWacAcSVsJCwwMsk96THqaSraikKO0EmBsQUxmdlOtolOt0jrAjegcR2c1Y6mCTXUW+gEdeIqHFiDYfGVfAXG0VyWNqSda52KK843uzE9EXIkF5SNApJPgUostHgs//D3ioFQk+R695NYzOecnvaV7iV6gk6N18F1qOGEqQkCIGrHSjO/yWoJ1t9FtcjwSeISKz6SvQrpJRC9G5r+i1yE3UP5DeCFlLYEU86P56TfcF9fSNbWRIWiuxJ9+TnNjCnw0QVw59lzURvvMZlrt+0IekVvqHl+AdLRgKOth/DwovPyR+Qj7W3DcsroaArS4Gb7hDdClYr35gfWgJB/ZVc9wN04t8jMxpYd2NdtKqQNKdYEqyIYFWfj7djssDaxduZVAXA+56SHdBn6hLaIx5JJRBWwcYtTOZPX4Vz97B3H9BJhYSFHuRbbdB0mpgxLzzyKhjaA+8IasU9sS0smpkKbt3NbPABt2mFSEheURegb3F7STpbRQWP2f/f2Aq8CEhZ+kflsuWChC/Omp+EfLDIQbEuYi2qCRGwXJjHbMW3SUgtRXRnUTGHmXgmgfEnOtg3d+vSaqBWVdVvk1oQgJP0N9kJaUSZHsNzDnoYmDERZbq29g4w0fcH160iU/ovaQRMVUiEwqQ8VZEcdAztp7hlqcEbHpMt2jnp3a/US7wS0YV226BpN6ClDIPG2beJ8vgI8voIUfvZehkJ2IsRWOuRH48gWiPIuabyKRyZKINMZSjmeJA9DbMlkfkNII/Qdl8zcPSaXZWj6gmc4pKhuIhM8zLMFMdEm6n668N9PitgR6RdXSNvIeE+7/p5qfrNLUC+cnG1OxmMms/d/gRW4lAVhe+peQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
    </Row>
    <h2>{`Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ArticleCard/ArticleCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ArticleCard",
        "path": "components/ArticleCard/ArticleCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ArticleCard"
      }}>{`<Row>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      subTitle="subTitle"
      title="Title"
      author="Josh Black"
      date="April 29, 2019"
      readTime="Read time: 5 min"
      href="/">
      
![Dark article layout mockup](/images/Article_06.png)


    </ArticleCard>

  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create"
      author="Josh Black"
      href="https://www.ibm.com"
      actionIcon="arrowRight">
      
![Dark article layout mockup](/images/Article_06.png)


    </ArticleCard>

  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create a longer title example in this space"
      href="https://www.ibm.com"
      disabled>
      
![Dark article layout mockup](/images/Article_06.png)


    </ArticleCard>

  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create"
      color="dark"
      href="https://www.ibm.com"
      actionIcon="download">
      
![Dark article layout mockup](/images/Article_06.png)


    </ArticleCard>

  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create"
      author="Josh Black"
      readTime="Read time: 5 min"
      color="dark"
      href="https://www.ibm.com"
      actionIcon="email">
      
![Dark article layout mockup](/images/Article_06.png)


    </ArticleCard>

  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create"
      author="Josh Black"
      date="April 29, 2019"
      readTime="Read time: 5 min"
      color="dark"
      disabled>
      
![Dark article layout mockup](/images/Article_06.png)


    </ArticleCard>

  </Column>
</Row>

`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use 32x32 image as child, will display in bottom left of card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set url for card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Card title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subTitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Card smaller sub title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`author`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Author of article`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date article published`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`readTime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Read time of article`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`launch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action icon, default is launch, options are `}<inlineCode parentName="td">{`Launch`}</inlineCode>{`, `}<inlineCode parentName="td">{`ArrowRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`Download`}</inlineCode>{`, `}<inlineCode parentName="td">{`Disabled`}</inlineCode>{`, `}<inlineCode parentName="td">{`Email`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`light`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to `}<inlineCode parentName="td">{`dark`}</inlineCode>{` for dark background`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set for disabled card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      